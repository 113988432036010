import Home from "Components/Pages/Home";
import Meta from "Components/Layout/Meta/index.jsx";
import Models from "Components/Pages/Models";
import ModelDetail from "Components/Pages/ModelDetail";
import ModelUnits from "Components/Pages/ModelUnits";
import Buy from "Components/Pages/Buy";
import Subscription from "Components/Pages/Subscription";
import PlanBooking from "Components/Pages/PlanBooking";
import Offers from "Components/Pages/Offers";
import Plans from "Components/Pages/Plans";
import Popups from "Components/Pages/Popups";
import Services from "Components/Pages/Services";
import PlansAllocation from "Components/Pages/PlansAllocation";
import Accessories from "Components/Pages/Accessories";
import Contact from "Components/Pages/Contact";
import ParametersContext from "Components/Context/Parameters";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import "./App.scss";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ParametersContext>
        <ScrollToTop />
        <div className="App">
          <Meta state="2" />
          <Popups />
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/modelos" exact>
              <Models />
            </Route>
            <Route path="/modelos/:family" exact>
              <ModelDetail />
            </Route>
            <Route path="/modelos/:family/:subfamily/unidades" exact>
              <ModelUnits />
            </Route>
            <Route
              path="/modelos/:family/oportunidades"
              render={(props) => <ModelUnits offers {...props} />}
              exact
            />
            <Route path="/reservar/:family/:id" exact>
              <Buy />
            </Route>
            <Route path="/autoahorro/subscripcion/:family/:id" exact>
              <Subscription />
            </Route>
            <Route path="/autoahorro/reserva/:id/:subscription" exact>
              <PlanBooking />
            </Route>
            <Route path="/oportunidades" exact>
              <Offers />
            </Route>
            <Route path="/autoahorro" exact>
              <Plans />
            </Route>
            <Route path="/autoahorro/adjudicacion" exact>
              <PlansAllocation />
            </Route>
            <Route path="/postventa" exact>
              <Services />
            </Route>
            <Route path="/accesorios" exact>
              <Accessories />
            </Route>
            <Route path="/contacto" exact>
              <Contact />
            </Route>
          </Switch>
        </div>
      </ParametersContext>
    </Router>
  );
}

export default App;
