import { Component } from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

export default class Footer extends Component {
  render() {
    let className = "footer";

    return (
      <div className={className}>
        <div className="grid">
          <div className="col20 s3">
            <div className="footer__nav">
              <div className="footer__nav__grid grid">
                <div className="col6 ">
                  <h5>Yacopini Sud</h5>
                  <div className="footer__nav__link-list ">
                    <Link to="/modelos">Modelos</Link>
                    <Link to="/oportunidades">Oportunidades</Link>
                    <Link to="/autoahorro">Autoahorro</Link>
                  </div>
                </div>
                <div className="col6 ">
                  <h5>Servicios</h5>
                  <div className="footer__nav__link-list ">
                    <Link to="/accesorios">Accesorios</Link>
                    <Link to="/postventa">Servicios de Taller</Link>
                    <a
                      href="https://territorioyacopini.com.ar/usados/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Usados
                    </a>
                  </div>
                </div>
                <div className="col6 ">
                  <h5>Turnos Taller</h5>
                  <div className="footer__nav__link-list ">
                    <a
                      href="https://www.territorioyacopini.com.ar/solicitar-turno/volkswagen"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Pedí tu turno
                    </a>
                  </div>
                </div>
                <div className="col6  ">
                  <h5>Redes Sociales</h5>
                  <div className="footer__nav__link-list ">
                    <a
                      href="https://twitter.com/YacopiniSud"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Twitter
                    </a>
                    <a
                      href="https://www.instagram.com/yacopinisudvolkswagen/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCJo-qUR7UGd1xMkWF6riKmw?sub_confirmation=1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Youtube
                    </a>
                    <a
                      href="https://www.facebook.com/YacopiniSudVolkswagen/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  </div>
                </div>
              </div>
              <div className="footer__nav__secondary">
                <div className="footer__nav__secondary__links">
                  <Link to="/contacto">Política de privacidad</Link>
                  {" | "}
                  <Link to="/contacto">Terminos y condiciones</Link>
                  {" | "}
                  <Link to="/contacto">Política de cookies</Link>
                </div>
                <div className="footer__nav__secondary__sign">
                  Yacopini Süd. {new Date().getFullYear()}
                </div>
              </div>
            </div>
            <div className="footer__legal">
              <h5>Texto legal de Yacopini Süd</h5>

              <div className="p p-s p-fluid">
                <ol>
                  <li>
                    {" "}
                    Fotos no contractuales. Las imágenes publicadas en este
                    sitio son de carácter ilustrativo.
                  </li>
                  <li>
                    Consulte equipamientos y especificaciones técnicas en
                    nuestro chat, o en nuestras sucursales: San Martin Sur 600,
                    Godoy Cruz, Mendoza, tels: +54 261 4222848 / +54 261 4439300
                    - Avenida Mitre 721, San Rafael, Mendoza, tels: +54 260
                    4424951
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
