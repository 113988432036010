import Footer from "Components/Layout/Footer/index.jsx";
import Breadcrumbs from "Components/Layout/Breadcrumbs/index.jsx";
import Button from "Components/Atoms/Button/index.jsx";
import SectionHero from "Components/Layout/SectionHero/index.jsx";
import Intro from "Components/Layout/Editorials/Intro/index.jsx";
import Basic from "Components/Layout/Editorials/Basic/index.jsx";
import ThreeColumns from "Components/Layout/Editorials/ThreeColumns/index.jsx";
import Image1 from "images/photos/Stages/16-9_transparent-on-quality-and-price.jpg";
import ContactForm from 'Components/Molecules/ContactForm';
import {Helmet} from "react-helmet";
import { useParameter } from 'Components/Context/Parameters';

function Contact() {
  const contactMethods = useParameter('contact_methods');

  const writeusTitle = <strong>Escribinos</strong>;

  const stageDesc = "En Yacopini Süd nuestro compromiso es con vos. Abajo encontrarás nuestros medios de contacto.";

  const breadcrumbsList = [
    { id: 1, name: "Página de inicio", link: "/" },
    { id: 2, name: "Contacto", link: "/contacto" },
  ];

  const contactTitle = <strong> Yacopini Süd</strong>;
  const gcTitle = (
    <>
      <strong>Godoy Cruz.</strong>
      <br />
      San Martin Sur 600.
    </>
  );
  const gcDescription = (
    <>
    <p className="p p-s p-smalltext">
      C.P. 5501. Godoy Cruz, Mendoza, Argentina.
      <br />
      <br />
      <a href="https://goo.gl/maps/1BtqNdjFfgFsgAC17" target="_blank" rel="noopener noreferrer"><Button>Ver Mapa</Button></a>
      <br />
      <br />
    </p>

      <h5>
        <strong>Teléfonos:</strong>
      </h5>
      <p>
        Tel: +54 261 4222848
        <br />Tel: +54 261 4439300.
        <br />
      </p>

      <h5>
        <strong>Horarios Ventas:</strong>
      </h5>
      <p>
        Lunes a Viernes: 10:00 a 14:00 - 15:00 a 19:00
        <br />
        Sábados: 10:00 a 13:00 <br />
      </p>
      <h5>
        <strong>Horarios de Administración:</strong>
      </h5>
      <p>
        Lunes a Viernes: 10:00 a 14:00 - 15:00 a 19:00
        <br />
      </p>
      <h5>
        <strong>Horarios de Postventa:</strong>
      </h5>
      <p>
        Lunes a Viernes: 08:00 a 14:00 - 15:00 a 20:00
        <br />
      </p>
    </>
  );

  const whatsApps = contactMethods.filter(method => method.whatsapp_number);

  const contactDescription = (
    <>
      <br />
      {whatsApps.length > 0 && (
        <>
        <h5>
          <strong>WhatsApp:</strong>
        </h5>
        <p>
          {whatsApps.map(wa => (
             <span key={wa.name}><a href={`https://wa.me/${wa.whatsapp_number}`} target="_blank" rel="noopener noreferrer">{wa.name}</a>: {wa.whatsapp_number}<br/></span>
          ))}
        </p>
      </>
    )}
      <h5>
        <strong>Redes Sociales:</strong>
      </h5>
      <p>
        <a
          href="https://www.facebook.com/YacopiniSudVolkswagen/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Facebook
        </a>{" "}
        <br />
        <a
          href="https://twitter.com/YacopiniSud"
          target="_blank"
          rel="noreferrer noopener"
        >
          Twitter
        </a>{" "}
        <br />
        <a
          href="https://www.youtube.com/channel/UCJo-qUR7UGd1xMkWF6riKmw?sub_confirmation=1"
          target="_blank"
          rel="noreferrer noopener"
        >
          Youtube
        </a>{" "}
        <br />
        <a
          href="https://www.instagram.com/yacopinisudvolkswagen/"
          rel="noreferrer noopener"
          target="_blank"
        >
          Instagram
        </a>{" "}
        <br />
      </p>
    </>
  );

  const basicGC = (
    <Basic layout="basic-6" title={gcTitle} description={gcDescription}></Basic>
  );
  const srTitle = (
    <>
      <strong>San Rafael.</strong>
      <br />
      Avenida Mitre 721.
    </>
  );
  const srDescription = (
    <>
    <p className="p p-s p-smalltext">
      C.P. 5600. San Rafael, Mendoza, Argentina.
      <br />
      <br />
      <a href="https://goo.gl/maps/PksEkac2fSEtTCZ86" target="_blank" rel="noopener noreferrer"><Button>Ver Mapa</Button></a>
      <br />
      <br />
    </p>

      <h5>
        <strong>Teléfonos:</strong>
      </h5>
      <p>
        Tel: +54 260 4424951
        <br />Celular: +54 260 4579899
        <br />
      </p>

      <h5>
        <strong>Horarios Ventas:</strong>
      </h5>
      <p>
        Lunes a Viernes: 9:00 a 14:00  - 15:00 a 19:00
        <br />
        Sábados: 10:00 a 13:00 <br />
      </p>
      <h5>
        <strong>Horarios de Administración:</strong>
      </h5>
      <p>
        Lunes a Viernes: 10:00 a 14:00 – 15:00 a 19:00
        <br />
      </p>
      <h5>
        <strong>Horarios de Postventa:</strong>
      </h5>
      <p>
        Lunes a Viernes: 9:00 a 14:00 – 15:00 a 18:00
        <br />
      </p>
    </>
  );

  const basicSR = (
    <Basic layout="basic-6" title={srTitle} description={srDescription}></Basic>
  );
  const basicContact = (
    <Basic
      layout="basic-6"
      title="Contactanos "
      description={contactDescription}
    />
  );

  return (
    <>
      <Helmet>
        <title>Yacopini Süd - Contacto</title>
        <meta name="description" content="Contactanos - Yacopini Süd - Mendoza - San Rafael" />
      </Helmet>
      <SectionHero
        title={contactTitle}
        image={Image1}
        description={stageDesc}
      />
      <Breadcrumbs linkList={breadcrumbsList} withCurrent />
      <ThreeColumns
        column1={basicGC}
        column2={basicSR}
        column3={basicContact}
      />
      <Intro title={writeusTitle} titleOnly />
      <ContactForm />

      <Footer />
    </>
  );
}

export default Contact;
